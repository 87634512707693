import React from 'react'

import Layout from '../components/layout'

const Heading = props => {
  return (
    <h2 className="pb-4 mt-8">
      {props.children}
    </h2>
  )
}

const TermsPage = () => (
  <Layout>
    <div className="bg-black text-white">
      <div className="container mx-auto">
        <div className="px-4 py-10">
          <h1 className="font-light text-4xl">Terms</h1>
        </div>
      </div>
    </div>

    <div className="container mx-auto pb-32">
      <div className="px-4 py-4 mb-8 leading-loose">
        <Heading>Terms of Service</Heading>
        <p>These terms and conditions outline the rules and regulations for the use of Whoops.io.</p> <br />

        <p>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use Whoops.io's website
                    if you do not accept all of the terms and conditions stated on this page.</p>
        <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
                    and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website
                    and accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers
                    to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client
                    or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake
                    the process of our assistance to the Client in the most appropriate manner, whether by formal meetings
                    of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect
                    of provision of the Company’s stated services/products, in accordance with and subject to, prevailing law
                    of . Any use of the above terminology or other words in the singular, plural,
                    capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
        <Heading>License</Heading>
        <p>Unless otherwise stated, Whoops.io and/or it’s licensors own the intellectual property rights for
                    all material on Whoops.io. All intellectual property rights are reserved. You may view and/or print
                    pages from https://whoops.io for your own personal use subject to restrictions set in these terms and conditions.</p>
        <p>You must not:</p>
        <ol>
          <li>Republish material from https://whoops.io</li>
          <li>Sell, rent or sub-license material from https://whoops.io</li>
          <li>Reproduce, duplicate or copy material from https://whoops.io</li>
        </ol>
        <p>Redistribute content from Whoops.io (unless content is specifically made for redistribution).</p>
        <Heading>Reservation of Rights</Heading>
        <p>We reserve the right at any time and in its sole discretion to request that you remove all links or any particular
                    link to our Web site. You agree to immediately remove all links to our Web site upon such request. We also
                    reserve the right to amend these terms and conditions and its linking policy at any time. By continuing
                    to link to our Web site, you agree to be bound to and abide by these linking terms and conditions.</p>
        <Heading>Pricing</Heading>
        <p>Pricing for Whoops.io is split into plans, include <strong>Free Plan</strong> and <strong>Pro Plan</strong></p>
        <p>Your usage of the Whoops.io platform will be limited, based on the plan which your workspace is currently
        subscribed to. Some of the limits of the aforementioned pricing plans are as follows:</p>
        <ul>
          <li>
            Free Plan
            <ul>
              <li>Maximum of 20 check definitions</li>
              <li>Minimum check interval for a single definition of 60 seconds</li>
              <li>Maximum of 2 users with access to a single workspace</li>
              <li>Check data is retained for a maximum of 30 days</li>
            </ul>
          </li>
          <li>
            Pro Plan
            <ul>
              <li>Maximum of 500 check definitions</li>
              <li>Minimum check interval for a single definition of 10 seconds</li>
              <li>Check data is retained for a maximum of 12 months</li>
            </ul>
          </li>
        </ul>
        <p>Plans, pricing, and limitations may change at any time, without notice.</p>
        <p>Whoops.io is currently in <em>public beta</em>. As the beta gains more users and the Whoops.io platform
        develops, it is very likely that these plans will change.</p>
        <p>Whenever a Whoops.io pricing plan changes, we will do our best to accommodate existing customers so that
        the changes do not cause a detremental impact on the pricing they are paying, or services they are using.</p>
        <p>Our aim is to always honour existing pricing plans for a minimum of 12 months for existing customers
        already signed up to those plans. However we may not be able to do so in the event that the pricing plan
        is not economically viable for Whoops.io</p>
        <p>For more information about limits and pricing, please contact hello@whoops.io.</p>
        <Heading>Removal of links from our website</Heading>
        <p>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact
                    us about this. We will consider requests to remove links but will have no obligation to do so or to respond
                    directly to you.</p>
        <p>Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness
                    or accuracy; nor do we commit to ensuring that the website remains available or that the material on the
                    website is kept up to date.</p>
        <Heading>Content Liability</Heading>
        <p>We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify
                    and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any
                    page on your Web site or within any context containing content or materials that may be interpreted as
                    libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or
                    other violation of, any third party rights.</p>
        <Heading>Disclaimer</Heading>
        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</p>
        <ol>
          <li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
          <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
          <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
          <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
        </ol>
        <p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a)
                    are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or
                    in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort
                    (including negligence) and for breach of statutory duty.</p>
        <p>To the extent that the website and the information and services on the website are provided free of charge,
                    we will not be liable for any loss or damage of any nature.</p>

      </div>
    </div>
  </Layout>
)

export default TermsPage
